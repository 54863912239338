import logo from './logo.svg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={`${process.env.PUBLIC_URL}logo.png`} className="App-logo" alt="logo" style={{ marginBottom: '70px' }} />
        <p style={{color: 'red',backgroundColor:'white',padding: '0 10px',fontWeight:'bold'}}>
          「大日本帝國の栄光を守れ！」
        </p>
        <a
          className="App-link"
          href="https://onlyfans.com/cutesexyevelyn"
          target="_blank"
          rel="noopener noreferrer"
        >
          Link to OnlyFans
        </a>
      </header>
    </div>
  );
}

export default App;
